
.apply-container{
    margin-top: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}


.apply-container .text-wrap{
    max-width: 600px;
    /* width: 100%; */
    margin-bottom: 30px;
    margin-left: 80px;
}
.apply-container .flex{
    justify-content: space-between;
}
.apply-container .text-wrap p{
    font-family: 'Raleway', sans-serif;
    font-weight: 550;
    font-size: 18px;
    line-height: 28px;
    max-width: 530px;
    width: 100%;
}
.apply-container .btn-wrap{
    margin: 40px 0;
}
.apply-container .btn-wrap button{
    /* background-color: var(--white); */
    max-width: 292px;
    width: 100%;
    height: 61.46px;
    font-family: 'Raleway' sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    /* color: var(--light-blue); */
    border-radius: 8px;
    transition: var(--transition);
}

.apply-container .btn-wrap button:hover{
    background: var(--bg-dark-green);
    color: var(--dark-blue);
}
@media screen and (max-width: 750px) {
    .apply-container .text-wrap{
        margin-left: 0;
    }
}

@media screen and (max-width: 768px) {
    .apply-container .btn-wrap button{
        font-size: 14px;
        height: 50px;
    }
    .apply-container{
        margin-top: 0px;
        padding-top: 2px;
        padding-bottom: 0px;
    }
    .apply-container .text-wrap p{
        font-size: 16px;
        font-weight: 500;
    }

    .marT-5 {
        margin-top: 30px;
    }
    .apply-container .apply-wrap{
        margin: 80px 0 0px;
    }
}