.contact-container{
    padding-top: 100px;
    padding-bottom: 100px;
    background: #EFFBF6;
}
.row{
    display: flex;
    gap: 30px;
}

.contact-header{
    max-width: 580px;
    width: 100%;
}
.contact-header h1{
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
    font-size: 42px;
    line-height: 53px;
    color: var(--light-blue);
    margin: 0 0 45px;
}
.contact-header p{
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    line-height: 1.8;
    color: var(--text-color-primary);
}

.row .col:nth-child(2){
    /* height: 85%; */
    /* margin-left: 30px; */
    max-width: 558px;
    width: 100%;
}

@media only screen and (max-width: 768px) {
   .row{
    flex-wrap: wrap;
   }
   .contact-header h1{
    font-size: 28px;
   }
   .contact-header p{
    font-size: 16px;
    margin-top: 0px;
    padding-top: 0px;
   }
   .contact-container{
    padding-top: 20px;
    padding-bottom: 20px;
}
.row .col:nth-child(2){
    margin-left: 0px;
}
}