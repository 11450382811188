:root{
    --bg-light-green: #F5FFFB;
    --bg-dark-green: #E3FFF3;
    --dark-blue: #001742;
    --light-blue: #0000B5;
    --white: #FFFFFF;
    --text-color-secondary: #000032;
    --text-color-primary: #3C3C3C;
    --text-green: #00C976;
    --transition : all .5s ease-in;
    --nav-text-color: #6F81A0;
    --disable: #c5cfdf;
}
.flex{
    display: flex;
    align-items: center;
}

.started-btn{
    background: var(--text-green);
}
.started-btn, .primary-btn{
    color: var(--white);
    font-family: 'Raleway', sans-serif;
    border-radius: 8px;
    transition: var(--transition);
    cursor: pointer;
}

.primary-btn{
    background: var(--light-blue);
}

.started-btn:hover{
    background: var(--dark-blue);
}

.container{
    padding: 0 90px; 
}
.content-wrapper{
    max-width: 1180px;
    margin: auto;
}
.padB-1{
    padding-bottom: 10px;
}
.marT-5{
    margin-top: 50px;
}
.card{
    border-radius: 8px;
    background-color: var(--bg-dark-green);
}
.hover, img{
    transition: var(--transition);
}
.hover:hover{
    transition: var(--transition);
    transform: scaleX(1.02);
}
.card .card-desc{
    background-image: url("../images/card-dec.svg");
    background-repeat: no-repeat;
    background-position: right bottom;
}
.bg-blue{
    background-image: url("../images/bg-img1.png");
    color: var(--white);
    background-repeat: no-repeat;
    background-size: cover;
}
.img-wrap{
    max-width: 100%;
}
.img-wrap img{
    width: 100%;
}
.bg-light-green{
    background-color: var(--bg-light-green);
}
.bg-dark-green{
    background-color: var(--bg-dark-green);
}

@media only screen and (max-width: 750px) {
    
    .order-1{
        order: 1;
    }
    .order-2{
        order: 2;
    }
    .flex{
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 820px) {
    .container{
        padding: 0 40px ;
    }
}