.contact-address{
    padding: 50px 30px 0 30px;
    /* height: auto; */
}
.contact-address .title{
    font-family: 'Raleway';
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: var(--text-green);
}

.contact-address p, .whatsapp-link a{
    max-width: 440px;
    width: 100%;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: var(--text-color-primary);
    margin-top: 10px;
}

.contact-address .address-wrap{
    margin: 50px 0 70px;
}

@media only screen and (max-width: 768px) {
    .contact-address{
        padding: 30px 0px 0 0px;
    }
    .contact-address .title{
        font-size: 16px;
    }
    .contact-address p{
        font-size: 14px;
        margin-top: 0px;
    }
    .contact-address .address-wrap{
        margin: 20px 0;
    }
}