.contact-form{
    max-width: 580px;
}
.contact-form .form-ctrl{
    padding: 10px 0;
}
.contact-form label{
    display: block;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px;  
    color: #9F9F9F;
}
.contact-form .form-ctrl input, 
.contact-form .form-ctrl textarea{
    border: none;
    outline: none;
    width: 95%;
    border-radius: 4px;
    padding: 10px;
    font-size: 18px;
}
.contact-form .form-ctrl input{
    height: 45px;
}
.contact-form .form-ctrl textarea{
    height: 349px;
}

.contact-form .send-btn{
    width: 175px;
    margin-left: auto;
}
.contact-form .send-btn button{
    width: 170px;
    height: 60px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.spinner{
    display: flex;
    margin-right: 5px;
    animation: rotate .40s infinite;
}
.contact-form .send-btn button:disabled{
    cursor: not-allowed;
    background-color: var(--disable);
}

@media only screen and (max-width: 768px) {
    .contact-form label{
        font-size: 14px;
        padding-bottom: 5px;
    }
    .contact-form .form-ctrl input{
        height: 40px;
        font-size: 12px;
    }
    .contact-form .send-btn button{
        font-size: 14px;
        height: 50px;
        align-content: flex-start;
    }
    .contact-form .send-btn{
        margin-left: 0;
    }
}

@keyframes rotate {
    from{
        transform: rotate(180deg);
    }
    to{
        transform: rotate(360deg);
    }
}