.faq-header{
    width: 85%;
}
.faq-header .title{
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
    font-size: 44px;
    color: var(--light-blue);
}
.faq-header p{
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: var(--text-color-primary);
}

@media only screen and (max-width: 750px) {
    .faq-header{
        width: 100%;
        margin: auto;
        text-align: center;
    }
    .faq-header .title{
        font-size: 32px;
    }

    .faq-header p{
        font-size: 16px;
    }
}