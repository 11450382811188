.about-container{
    margin-top: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.about-wrap .flex{
    justify-content: space-between;
    margin: 20px auto;
    gap: 20px;
}

.about-container .text-wrap{
    max-width: 500px;
    width: 100%;
}
.about-container .text-wrap p{
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.8rem;
    margin-top: 30px;
    color: var(--text-color-primary);
    text-align: justify;
}
.about-container .text-wrap h1{
    font-family: "Exo 2", sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 42px;
    margin-bottom: 60px;
    color: var(--light-blue);
}
@media screen and (max-width: 750px) {
    .about-container .text-wrap{
        margin-right: 0;
    }
    .about-container{
        margin-top: 0px;
        padding-top: 0px;
        padding-bottom: 10px;
    }
    .about-container .text-wrap{
        margin: 0 10px 0 0;
    }
    .about-container .text-wrap h1{
        margin-bottom: 0px;
        font-size: 32px;
    }
    .about-container .text-wrap p{
        font-size: 16px;
        margin: 10px 0px;
    }
}