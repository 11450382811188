.download-mobile{
    /* padding-top: 50px; */
    /* padding-bottom: 50px; */
    /* min-height: 80vh; */
    background-image: url("../../../assest/images/bg-pattern.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.download-flex{
    justify-content: space-between;
    padding: 50px 0;
    position: relative;
}
.download-flex .text-wrap h1{
    color: var(--light-blue);
    font-family: "Exo 2", sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
}
.download-flex .text-wrap{
    max-width: 490px;
    width: 100%;
}
.download-flex .text-wrap p{
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: var(--text-color-primary); 
    line-height: 15px;   
}
.download-flex .download-btn{
    margin-top: 50px;
    justify-content: unset;
}
.download-btn div:first-child{
    margin-right: 30px;
}

.download-flex .app-img{
    position: relative;
    right: 0;
    bottom: -55px;
}
@media only screen and (min-width: 1180px) {
    .app-img .img-wrap:first-child{
        margin-right: -75px;
      } 
      
}

@media only screen and (max-width: 750px) {
    .download-flex .text-wrap h1{
        font-size: 22px;
      }
      .download-flex .text-wrap p{
        font-size: 16px;
      }
      .download-flex .download-btn{
        margin-top: 30px;
    }
    .download-btn div:first-child{
        margin-right: 10px;
    }
}