.service-delivery {
  padding-top: 60px;
  padding-bottom: 40px;
  font-family: "Raleway", "sans-serif";
  text-align: center;
}
.delivery-wrap h1 {
  color: #00006B;
  font-weight: 600;
  font-size: 38px;
  font-family: "Exo 2", "sans-serif";
}
.delivery-wrap .flex{
  /* justify-content: space-between; */
  gap: 40px;
}
.delivery-wrap .card {
  margin: 20px 0 60px;
  max-width: 600px;
  width: 100%;
}
.delivery-wrap .card:nth-child(1) {
  margin-left: 0;
}
.delivery-wrap .card:last-child {
  margin-right: 0;
}

.delivery-wrap .card .card-desc {
  height: 100px;
  justify-content: center;
  background-size: 20%;
  background-position: right bottom;
  font-weight: 550 !important;
  font-size: 20px;
  color: #3c3c3c;
}

/* .service-delivery .more {
  width: 200px;
  margin-left: auto;
  text-align: right;
} */
.service-delivery .more a {
  color: var(--light-blue);
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (max-width: 750px) {
  .delivery-wrap .card {
    margin: 20px 0 20px 0 !important;
  }
  .delivery-wrap h1 {
    font-size: 22px;
  }
  .delivery-wrap .card .card-desc {
    font-size: 16px;
    height: 70px;
  }
  .service-delivery .more a {
    font-size: 14px;
  }
  .service-delivery {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}
