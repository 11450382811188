
.faq-flex{
    display: flex;
    align-items: center;
    padding: 0 20px;
}
.faq-card .heading{
    height: 80px;
    border-radius: 8px 8px 0px 0px;
    color: #00C976;
    font-weight: 500;
    font-size: 24px;
    font-family: 'Exo 2', sans-serif;
}

.faq-card .body{
    min-height: 70px;
    font-weight: 500;
    font-size: 18px; 
    font-family: 'Raleway', sans-serif;
    color: var(--text-color-primary);

}
.faq-card{
    margin: 10px 0 40px;
} 

@media only screen and (max-width: 750px) {
    .faq-card .heading{
        height: unset;
        min-height: 80px;
        font-size: 16px;
        line-height: 1.4;
    }
    .faq-card .body{
        font-size: 14px; 
        line-height: 1.6;
    }
}