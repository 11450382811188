.testimonials-container{
    padding-top: 50px;
    padding-bottom: 50px;
}
.testimonials-container .title{
    margin: 40px 0 70px;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: var(--light-blue);
    font-family: 'Exo 2', sans-serif;
}
.test-flex{
    justify-content: center;
    font-family: 'Raleway', sans-serif;
}
.testimonial-card{
    max-width: 368px;
    width: 100%;
    min-height: 150px;
    padding: 15px 0 30px;
    background-color: var(--white);
    border: 0.8px solid #FFFFFF;
    box-shadow: 0px 12px 22px rgba(0, 0, 50, 0.1);
    border-radius: 12px;
    margin: 10px 20px;
}
.testimonial-card:last-child{
    margin-right: 0;
}
.testimonial-card:first-child{
    margin-left: 0;
}
.testimonial-card .text-wrap{
    max-width: 320px;
    width: 90%;
    margin: auto;
}

.testimonial-card p{
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--text-color-secondary);
}
.testimonial-card .customer-info{
    display: flex;
    align-items: center;
    padding-top: 10px;
}
.testimonial-card .customer-info span{  
    margin-left: 10px;
    font-weight: 700;
    font-size: 16px;
    color: var(--light-blue);
    line-height: 19px;
}

@media only screen and (max-width: 750px) {
    .testimonial-card{
       margin: 20px auto 20px auto!important;
    }
    .test-flex{
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    .testimonials-container .title{
        font-size: 24px;
        margin: 0px 0 10px;
    }
}