.work-container{
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: "Raleway", sans-serif;
    margin: 20px auto;

}

.work-wrap .work-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    gap: 20px;
    margin-top: 50px;
}

.work-container .text-wrap{
    max-width: 450px;
    /* width: 100%; */
}
.work-container .text-wrap p{
    font-weight: 550;
    font-size: 18px;
    line-height: 28px;
    margin: 40px 0;
    color: var(--text-color-primary);
    text-align: justify;
}

.work-container .text-wrap h1{
    font-family: "Exo 2", sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 30px;
    color: var(--text-green);
}
.work-container .work-header .title{
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
    font-size: 44px;
    line-height: 53px;
    color: var(--light-blue);
}
.work-container .work-btn{
    margin-top: 70px;
}
.work-container .work-btn button{
    max-width: 280px;
    width: 100%;
    height: 60px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
}

@media screen and (max-width: 750px) {
    .work-flex{
        flex-wrap: wrap;
    }
    .work-container .text-wrap{
        margin-right: 0;
    }
    .work-container .work-btn button{
        font-size: 20px;
    }
}

@media screen and (max-width: 768px) {
    .work-container .text-wrap h1{
        font-size: 24px;
        margin-bottom: 0px;
      }
      .work-container{
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0px auto;
    }
    .work-container .work-header .title{
        font-size: 28px;
        line-height: 1.6;
    }
    .work-container .text-wrap{
        margin: 0;
    }
    .work-container .text-wrap h1{
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .work-container .text-wrap p{
        margin: 0px ;
        font-size: 16px;
    }
    .work-container .work-btn{
        margin: 30px 0 50px;
    }
    .work-wrap .work-flex{
        margin-top: 20px;
    }
    .work-container .work-btn button{
        font-size: 14px;
        height: 50px;
    }

}