.vision-container{
    padding-top: 50px;
    padding-bottom: 50px;
}
.vision-wrap .vision-flex{
    text-align: center;
}
.item-wrap{
    padding: 40px 0;
}
.vision-container .title{
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: var(--light-blue);
}
.vision-desc p{
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
}
.vision-desc{
    max-width: 680px;
    width: 100%;
    margin: auto;
}

@media screen and (max-width: 750px) {
    .vision-container .title{
        font-size: 28px;
    }
    .item-wrap{
        padding: 10px 0;
    }
    .vision-desc p{
        font-size: 16px;
        line-height: 1.6;
    }
    .vision-container{
        padding-top: 30px;
        padding-bottom: 0px;
    }
}