.core-values .text-wrap .core-desc{
    max-width: 770px;
    width: 100%;
}

.core-values .core-value-flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.core-value-flex .img-wrap{
   max-width: 8%;
}

.core-values{
    padding: 20px 0;
    font-family: 'Raleway', sans-serif;
}
.core-values .core-title{
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: var(--text-green);
    margin-bottom: 24px;
}

.core-values .core-desc{
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
}
.core-values .values{
    padding: 80px 0;
}
.core-values .values:nth-child(even) .text-wrap{
    order: 1;
}
.core-values .values:nth-child(even) .img-wrap{
    order: 2;
}


@media only screen and (max-width: 768px) {
    .hero-container .fast{
        font-size: 30px;
    } 
    .core-values .text-wrap{
        text-align: center;
    }
    .core-values .core-value-flex{
        display: block;
    }
    .core-value-flex .img-wrap{
        max-width: 50%;
        margin: auto;
    }
    .img-wrap {
        text-align: center;
    }
    .core-values .values{
        padding: 10px 0;
    }
    .core-values .core-title{
        font-size: 18px;
    }
    .core-values .core-desc{
        font-size: 16px;
    }
}