.terms{
    padding-top: 50px;
    padding-bottom: 80px ;
}
.terms .title{
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
    font-size: 44px;
    color: var(--light-blue);
    margin: 30px 0;
}
.terms .terms-list{
    font-family: 'Exo 2';
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: var(--text-color-primary);
    margin: 0;
    padding: 0;
}

.terms .terms-list li{
    margin: 50px 0;
}