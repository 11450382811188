
nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 90px 20px;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 14px;
}
nav .logo-wrap{
    display: flex;
    align-items: center;
}
.menu-list{
    list-style: none;
}
.mobile-toggle{
    visibility: hidden;
}
.menu-list li{
    display: inline;
    margin-right: 20px;
    height: 40px;
    font-weight: 400;
}
.menu-list li:last-child{
    margin-right: 0;
}
.menu-list li .links{
    transition: var(--transition);
    text-decoration: none;
    color: var(--nav-text-color);
}
.menu-list li .selected,
.menu-list li .links:hover{
    color: var(--text-green);
}
.menu-list button{
    height: 40px;
}
.menu-list .loginBtn{
    color: var(--light-blue);
    font-weight: 500;
    background: transparent;
}
.menu-list .signupBtn{
    background: var(--light-blue);
    width: 100px;
    border-radius: 8px;
    font-weight: 600;
}

@media only screen and (max-width: 950px) {
    nav{
        padding: 20px 20px;
        display: block;
        position: relative;
    }
    .logo img{
        width: 70%;
    }
    .logo-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .menu-list{
        margin: 0;
        padding: 30px 0 50px 20px;
        position: absolute;
        background-color: white;
        width: 100%;
        left: 0;
        height: 100vh;
    }
   
    .menu-list li{
        display: block;
    }
    .mobile-hidden{
        display: none;
    }
    .mobile-toggle{
        visibility: visible;
        transition: all 1s ease-in .3s;
    }
}

@media only screen and (max-width: 750px) {
    .logo img{
        width: 70%;
    }
}