.history-container{
    margin-top: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.history-wrap .flex{
    justify-content: space-between;
    margin: 20px auto;
    gap: 20px;
}

.history-container .text-wrap{
    max-width: 500px;
    width: 100%;
}
.history-container .text-wrap p{
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.8rem;
    margin: 30px 0;
}
.history-container .text-wrap h1{
    font-family: "Exo 2", sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 40px;
}

.green-txt{
    color: var(--text-green);
    font-weight: 700;
}

.history-container .text-wrap a{
    color: var(--white);
    padding-left: 5px;
}

@media screen and (max-width: 750px) {
    .history-container .text-wrap{
        margin-left: 0;
    }
    .history-container .text-wrap p{
        font-size: 16px;
        margin: 10px 0;
    }
    .history-container .text-wrap h1{
        font-size: 32px;
        margin-bottom: 0px;
    }
    .history-container{
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}