.career-core {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url("../../../assest/images/bg-pattern.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.core-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
}

.core-flex .flex-wrap {
  flex: 1 0 30%;
  margin: 30px 0;
}

.career-core .career-core-header .title {
  font-family: "Exo 2", sans-serif;
  font-weight: 700;
  font-size: 44px;
  line-height: 53px;
  color: var(--light-blue);
}
.career-core .core-flex .core-label {
  font-family: "Raleway", sans-serif;
  font-weight: 550;
  font-size: 18px;
  line-height: 38px;
  color: var(--text-green);
}

.career-core .core-flex img {
  width: 20%;
}
@media screen and (max-width: 768px) {
  .career-core .core-flex img {
    width: 80%;
  }
  .career-core .career-core-header .title {
    font-size: 28px;
  }
  .core-flex .flex-wrap {
    flex: 1 0 40%;
    margin: 10px 0;
  }
  .career-core {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 0px;
  }
}
