.hero-container{
    padding-top: 30px;
}
.hero-container .text-wrap{
    font-family: 'Exo 2', sans-serif;
    max-width: 550px;
    width: 100%;
}
.text-wrap .deco{
    margin-bottom: -50px;
}
.hero-container .fast{
    height: 80px;
    font-weight: 700;
    font-size: 42px;
    color: #E3FFF3;
}
.hero-wrap{
    padding-left: 50px;
    padding-top: 50px;
    justify-content: space-between;
    width: 100%;
     background-image: url("../../../assest/images/slidebg.png");
    background-size: cover;
}
.hero-container .call-away{
    padding: 50px 0 40px;
    font-weight: 500;
    font-size: 32px;
    color: var(--white);

}
.hero-container .start-btn{
    height: 60px;
    max-width: 289px;
    width: 100%;
    font-size: 18px;
    font-weight: 550;
}
.hero-container .img-wrap img{
    width: 100%;
    margin-bottom: -50px;
}

@media only screen and (max-width: 900px) {
    .hero-container .fast{
        font-size: 30px;
        padding-top: 30px;
    } 
   .hero-container .order-2{
        margin-right: 20px;
        padding-bottom: 20px;
    }
}
@media only screen and (max-width: 750px) {
    .hero-container{
        padding-top: 40px;
        padding-bottom: 10px;
    }
    .hero-container .call-away{
        padding: 5px 0 30px;
        font-size: 20px;
    }
    .hero-container .fast{
        padding-bottom: 0px!important;
        height: unset;
        margin: 20px 0;
    }
    .hero-container .start-btn{
        height: 50px;
        font-size: 14px;
        max-width: 100%
    }
    .text-wrap .deco{
        display: none;
    }
}
