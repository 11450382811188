.modal-content h1{
    font-family: 'Exo 2';
    font-weight: 700;
    font-size: 96px;
    margin-bottom: 0;
    color: var(--text-green);
}
.modal-content p{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    width: 354px;
    height: 56px;
    margin: auto;
    color: #004F2E;
}
.modal-content{
    padding: 30px 0;
}
