.faq{
    padding-top: 80px;
    padding-bottom: 120px;
}

.faq .faq-header-container .faq-header{
    margin: 30px 0;
}
.faq .faq-header-container .faq-header p{
    margin-top: 60px;
    width: 90%;
}
.faq .faq-list{
    margin: 90px 0;
}
.faq .faq-support{
    margin: 80px 0 60px;
}
.faq .faq-support p{
    font-family: 'Exo 2', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--text-green);
}

.faq .faq-support .support-mail-wrap{
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
}

.faq .faq-support .support-mail{
    color: var(--light-blue);
}