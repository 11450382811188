.services {
  padding-top: 50px;
  padding-bottom: 50px;
}
.services-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  /* justify-content: space-between; */
}
.services-header .title {
  font-family: "Exo 2", sans-serif;
  font-weight: 700;
  font-size: 42px;
  color: var(--light-blue);
  margin-bottom: 40px;
}
.services-header p {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-color-primary);
}

.services-header {
  max-width: 1080px;
  width: 100%;
  margin: 30px 0 60px;
}
.services-list .service-card {
  max-width: 800px;
  width: 100%;
  margin: 30px 0 30px;
  flex: 1 0 45%;
}
.service-card .card-desc {
  justify-content: center;
  text-align: center;
  font-family: "Raleway", sans-serif;
}
.service-card .book-btn button {
  max-width: 240px;
  width: 100%;
  height: 60px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.service-card .book-btn {
  margin: 40px 0 80px;
}
.service-card .card-wrap .label {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: var(--text-color-primary);
}
.service-card .card-wrap .sub-title,
.service-card .card-wrap .desc {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
}

.card-desc .card-wrap {
  max-width: 350px;
  width: 100%;
  margin: auto;
  position: relative;
}
.card-wrap .desc-text {
  height: 160px;
}
.service-card .card-img {
  height: 315px;
}
.service-card .card-img img {
  height: 100%;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
}
/* .service-card{
    border-radius: 12px
} */

@media only screen and (max-width: 768px) {
  .services {
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .services-header .title {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .services-header p {
    font-size: 16px;
  }
  .services-header {
    margin: 30px 0 0px;
  }
  .services-list .service-card {
    margin: 20px 5px;
    max-width: 550px;
    flex: 1 0 50%;
  }
  .service-card .card-wrap .label {
    font-size: 18px;
  }
  .service-card .card-wrap .sub-title,
  .service-card .card-wrap .desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.6;
  }
  .service-card .book-btn button {
    font-size: 14px;
    height: 50px;
  }
  .service-card .book-btn {
    margin: 20px 0 40px;
  }
  .service-card .desc-text {
    height: unset;
  }
}
