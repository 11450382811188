
.rider-container{
    margin-top: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.rider-container .text-wrap{
    max-width: 550px;
    width: 100%;
    margin-bottom: 30px;
}
.rider-container .flex{
    justify-content: space-between;
}
.rider-container .text-wrap h1{
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
    font-size: 40px;
}
.rider-container .text-wrap p{
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 500;
    max-width: 434px;
    width: 100%;
    line-height: 30px;
}
.rider-container .btn-wrap{
    margin: 50px 0 40px;
}
.rider-container .btn-wrap button{
    max-width: 292px;
    width: 100%;
    height: 61.46px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: var(--white);
    border-radius: 8px;
    transition: var(--transition);
}

@media only screen and (max-width: 750px) {
    .rider-container .rider-wrap{
        margin: 20px 0 30px;
    }
    .rider-container .text-wrap h1{
        font-size: 22px;
    }
    .rider-container .text-wrap p{
        font-size: 16px;
    }
    .rider-container .btn-wrap button{
        font-size: 14px;
        height: 50px;
    }
    .rider-container{
        padding-top: 30px;
    }
}