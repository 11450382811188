
.home-faq{
    padding-top: 100px;
    padding-bottom: 70px;
}
.home-faq-card{
    border: 1px solid rgba(111, 129, 160, 0.24);
    box-shadow: 0px 12px 48px 5px rgba(0, 0, 50, 0.06);
    border-radius: 16px;
    height: 480px;
    overflow: hidden;

}
.home-faq-card .faq-card{
    margin: 20px 0;
}
.home-faq-card .faq-wrapper{
    height: 400px;
    width: 100%;
    margin:  auto;
    padding: 20px;
    overflow-y: scroll;
    box-sizing: content-box;
   scroll-behavior: smooth;
   -ms-overflow-style: none;  /* Internet Explorer 10+ */
   scrollbar-width: none;  /* Firefox */
}

.home-faq-card .faq-wrapper::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.home-faq .more{
    text-align: center;
    padding: 40px 0 0;
    margin-top: 20px;
}
.home-faq .more a{
    color: var(--light-blue);
    font-weight: 500;
    font-size: 16px;
    opacity: 0.8;
    font-family: 'Raleway', sans-serif;
}

