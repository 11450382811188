.footer-container {
  padding-top: 40px;
  padding-bottom: 50px;
  background-color: var(--dark-blue);
  color: var(--white);
  font-family: "Raleway", sans-serif;
  font-size: 20px;
}

.footer {
  /* justify-content: space-between; */
  align-items: flex-start;
  gap: 60px;
  margin: 20px 0 25px;
}

.footer h1{
  margin-left: 0;
}

.footer-container .reserved {
  text-align: center;
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
}

.last-col {
  padding-left: 8.5rem;
}

.footer-container .reserved a {
  text-decoration: none;
  color: #fff;
}
.footer ul {
  list-style: none;
  margin: 0;
  padding: 5px 0;
  font-family: "Inter", sans-serif;
}
.footer h1 {
  font-weight: 700;
  font-size: 22px;
  margin-top: 0;
  letter-spacing: 1px;
}

.resource-track li {
  letter-spacing: 1px;
}
.footer ul li {
  margin: 20px 0;
  font-weight: 500;
  font-size: 14px;
}
.footer ul li a {
  font-family: "Inter", sans-serif;
  color: var(--white);
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  transition: var(--transition);
  /* letter-spacing: 1.2px; */
}
.footer ul li a:hover {
  color: var(--text-green);
}
.footer .col:last-child {
  max-width: 300px;
  width: 100%;
}
.footer .col:first-child {
  max-width: 350px;
  font-weight: 500;
  font-size: 20px;
}
.footer-logo {
  margin-bottom: 25px;
}
.footer-logo img {
  width: 80%;
}
.footer .col {
  margin: 20px 20px 20px 0;
}
.footer .icon-wrap {
  max-width: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.footer .icon-wrap span {
  cursor: pointer;
}
.footer .icon-wrap svg path:hover {
  fill: var(--text-green);
}

@media only screen and (max-width: 750px) {
    .footer-container .reserved {
        line-height: 1.6;
        margin-top: 0px!important;
    }
    .footer {
        margin: 20px 0 15px;
        gap: 0;
    }
    .last-col {
      padding-left: 0rem;
    }
}